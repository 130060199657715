// 通用函数
import useClipboard from 'vue-clipboard3'
import { ElMessage } from 'element-plus'

export default function () {
  const { toClipboard } = useClipboard()

  // 百分比格式化
  const percentFormat = (row, column, cellValue) => {
    return cellValue ? `${cellValue}%` : '-'
  }
  // 小数格式化
  const scaleFormat = (value = '0', scale = 4) => {
    return Number.parseFloat(value).toFixed(scale)
  }
  // 小数格式化
  const scale2Format = (value = '0') => {
    return Number.parseFloat(value).toFixed(2)
  }
  // 点击复制文本
  const copyText = (text) => {
    return new Promise((resolve, reject) => {
      try {
        // 复制
        toClipboard(text)
        // 下面可以设置复制成功的提示框等操作
        ElMessage.success('success!')
        resolve(text)
      } catch (e) {
        // 复制失败
        ElMessage.error('error!')
        reject(e)
      }
    })
  }
  return {
    percentFormat,
    scaleFormat,
    scale2Format,
    copyText
  }
}
