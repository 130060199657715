<template>
  <div class="add-new-user">
    <el-dialog :title="t('addLicense')" :model-value="modelValue" @close="closed" width="35%">
      <div class="content">
        <el-form :model="addLicenseForm" :rules="rules" ref="addLicenseFormRef" size="small">
          <el-form-item prop="type" :label="t('license_type')">
<!--            <el-checkbox-group v-model="addLicenseForm.type">
              <el-checkbox-button label="SW"> {{ t('sw') }}</el-checkbox-button>
              <el-checkbox-button label="PD"> {{ t('pd') }} </el-checkbox-button>
            </el-checkbox-group>-->
             <el-select v-model="addLicenseForm.type" placeholder="SW Type">
              <el-option key="SW" label="Pro(电量划分)" value="SW" />
              <el-option key="PD" label="Max(预付费)" value="PD" />
            </el-select>
          </el-form-item>

          <el-form-item prop="swid" :label="t('sw_Id')">
            <el-input
              :placeholder="t('sw_Id')"
              v-model="addLicenseForm.swid"
              name="iducount"
              type="text"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="closed">Cancel</el-button> -->
          <el-button type="primary" style="width: 100%" @click="submit">
            {{ t('generate') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { generateCurUserLicense } from '@/api/user'
import { useStore } from 'vuex'
const { t } = useI18n()

const store = useStore()

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  siteId: {
    type: String,
    required: true
  }
})
const addLicenseFormRef = ref(null)
const addLicenseForm = ref({})
addLicenseForm.value = { type: 'SW', swid: '' }
const emits = defineEmits(['update:modelValue', 'getListData'])

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
  addLicenseForm.value = { type: 'SW', swid: '' }
}

const rules = ref({
  // type: [
  //   {
  //     required: true,
  //     trigger: 'blur',
  //     message: 'please select type'
  //   }
  // ],
  swid: [
    {
      required: true,
      trigger: 'blur',
      message: t('sw_Id') + t('must_be_filled')
    }
  ]
})

const submit = () => {
  addLicenseFormRef.value.validate(async valid => {
    if (!valid) return
    try {
      const license = {
        // siteid: props.siteId,
        userid: store.getters.userInfo.userId,
        swid: addLicenseForm.value.swid,
        type: addLicenseForm.value.type || 'SW'
      }
      console.log(license)
      await generateCurUserLicense(license)
      // await generateLicense(license)
      ElMessage({
        message: t('add') + t('success'),
        grouping: true,
        type: 'success',
        customClass: 'divSuccessElMessage'
      })
      emits('getListData')
      closed()
    } catch (e) {
      console.error(e)
    }
  })
}

watch(
  props.modelValue,
  () => {
    addLicenseForm.value = { type: 'SW', swid: '' }
  },
  { immediate: true }
)
</script>

<style scoped lang="scss">
.my-info {
  .content {
    font-size: 16px;
    font-weight: 600;
  }
}
::v-deep .el-dialog__body {
  border-bottom: 1px solid var(--border-el-primary);
}
::v-deep .el-dialog__footer {
  padding-top: 16px;
}
::v-deep .el-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
::v-deep .el-form-item__content {
  width: 100%;
}
::v-deep .el-checkbox-button {
  width: 50%;
}
::v-deep .el-checkbox-button--small .el-checkbox-button__inner {
  width: 100%;
}
</style>
