import request from '@/utils/request'

/**
 * 创建user
 */
export const createNewUsers = data => {
  return request({
    url: '/createNewUsers',
    method: 'POST',
    data
  })
}

/**
 * 授权user
 */
export const approveUser = data => {
  return request({
    url: '/approveUser',
    method: 'POST',
    data
  })
}

/**
 * get current user site
 */
export const getAllusers = (data) => {
  return request({
    url: '/getAllusers',
    method: 'get',
    params: data
  })
}
/**
 * get current user site
 */
export const getAllPendingLoginUsers = (data) => {
  return request({
    url: '/getAllPendingLoginUsers',
    method: 'get',
    params: data
  })
}

/**
 * delete user site
 */
export const deleteUser = data => {
  return request({
    url: '/deleteUsers/',
    method: 'delete',
    params: data
  })
}

export const getCurUserAllLicenses = (userId, data) => {
  return request({
    url: `/getCurUserAllLicenses/${userId}`,
    method: 'get',
    params: data
  })
}

export const generateCurUserLicense = data => {
  return request({
    url: `/generateCurUserLicense/${data.userid}/${data.swid}/${data.type}`,
    method: 'post',
    data
  })
}
