import request from '@/utils/request'

/**
 * generate license
 */
export const generateLicense = data => {
  return request({
    url: `/generateLicense/${data.siteid}/${data.swid}/${data.type}`,
    method: 'post',
    data
  })
}
/**
 * delete license
 */
export const deleteSiteLicenses = data => {
  return request({
    url: '/deleteSiteLicensesUsingGet',
    method: 'get',
    params: data
  })
}

/**
 * delete license
 */
export const getAllPendingLicenses = data => {
  return request({
    url: '/getAllPendingLicenses/',
    method: 'get',
    params: data
  })
}

/**
 * apply license
 */
export const applypdlicenseapproval = data => {
  return request({
    url: '/applypdlicenseapproval',
    method: 'post',
    data
  })
}
